














































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'

import {
  searchRecognizeLog,
  downloadRecognizeLog,
  getLogFile,
} from '../../../interfaces/service/recognize/deviceRecognize'
import { getRanterTreeData } from '../../../interfaces/service/org/enterpriseMang'
import { getBase64 } from '../../../interfaces/service/person/personMang'
import _, { isLength, lte, parseInt } from 'lodash'
import moment from 'moment'
import GuilderHeadButton from '../../../views/main/bottom_guild/GuilderHeadButton.vue'
import svgDownload from '../../../components/svg_saves/user_authority_show/Download.vue'
import { getDeviceDataIntable } from '../../../interfaces/service/device/deviceHardWare'
import { getPersonBranchORG } from '../../../interfaces/service/person/userAuthority'
import defaultImg from '@/assets/image.png'

@Component({
  components: { GuilderHeadButton, svgDownload },
  data() {
    return {
      svgDownload,
      form: this.$form.createForm(this),
      editable: false,
      // 表格edit状态
      searchText: '',
      searchedColumn: null,
      searchInput: null,
    }
  },
  methods: {},
  computed: {},
})
export default class LogListShow extends Vue {
  public $refs!: {
    table: HTMLFormElement
    code: HTMLFormElement
    name: HTMLFormElement
  }
  public theWindowH = document.body.clientHeight
  public scrollFix: string = ''
  public tableData: any[] = []
  public tableDataCache: any[] = [] // 表格数缓存，供搜索使用
  public theWindowW = document.body.clientWidth
  public loading: boolean = true
  public userType: number = this.$store.state.main.userProfile.user_type
  // ------弹出对话框-----------
  public visible: boolean = false
  public confirmLoading: boolean = false
  public csvUploadfilelist: any = []
  // ------弹出对话框-----------
  // 表格分页，过滤，排序情况
  public thePagination: any = null
  public theFilters: any = null
  public theSorter: any = { order: null, field: null }
  // 展开tree时触发事件
  public ranterIDCose = Number(sessionStorage.getItem('ranterId'))
  public expandedRowKeys = [this.ranterIDCose]
  public dataResult: any = {
    items: [],
    total_num: 0,
  }
  public currentPage: number = 1
  public pageSize: number = 8
  public resultWidth: number = 1120
  public pageSizeForCard: number = 8

  // 列表显示还是卡片显示
  public listMode: boolean = false

  public resetConformFlag: boolean = false
  public showDeletOrgNames: string[] = []

  public orgList: any = []
  public deviceList: any = []
  public personList: any = []
  public deviceListT: any = []
  public personListT: any = []
  public form: any = null
  public defaultSearch: any = {
    org_id: null,
    device_id: null,
    person_id: null,
    time_zone: null,
    start_time: moment(new Date(), 'YYYY-MM-DD'),
    end_time: null,
    alcohol_min: null,
    alcohol_max: null,
  }

  public logImg: any = {}

  get routePath() {
    const routes = this.$route.path.split('/')
    return routes.length > 0 ? routes[routes.length - 1] : ''
  }

  get columns() {
    const listColumns = [
      // 番号
      {
        title: this.$i18n.tc('log.column.id'),
        dataIndex: 'id',
        key: 'id',
        width: 40,
        align: 'center',
      },
      // 顔写真
      {
        title: this.$i18n.tc('log.column.faceimg'),
        dataIndex: 'faceimg',
        key: 'faceimg',
        width: 100,
        align: 'center',
        scopedSlots: { customRender: 'faceimg' },
      },
      // 乗務員名前
      {
        title: this.$i18n.tc('log.column.personname'),
        dataIndex: 'personname',
        key: 'personname',
        width: 80,
        align: 'center',
      },
      // 乗務員ID
      {
        title: this.$i18n.tc('log.column.personid'),
        dataIndex: 'personid',
        key: 'personid',
        width: 80,
        align: 'center',
      },
      // 所属
      {
        title: this.$i18n.tc('log.column.org'),
        dataIndex: 'org',
        key: 'org',
        width: 120,
        align: 'center',
      },
      // ログ種別
      {
        title: this.$i18n.tc('log.column.logtype'),
        dataIndex: 'logtype',
        key: 'logtype',
        width: 80,
        align: 'center',
      },
      // 体温
      {
        title: this.$i18n.tc('log.column.temperature'),
        dataIndex: 'temperature',
        key: 'temperature',
        width: 100,
        align: 'center',
      },
      // アルコール
      {
        title: this.$i18n.tc('log.seacrch.alcohol'),
        dataIndex: 'alcohol',
        key: 'alcohol',
        width: 100,
        align: 'center',
        scopedSlots: { customRender: 'alcohol' },
      },
      // デバイス
      {
        title: this.$i18n.tc('device.new.device'),
        dataIndex: 'device',
        key: 'device',
        width: 100,
        align: 'center',
      },
      // 運転免許証
      {
        title: this.$i18n.tc('user.authority.certificate.driverLicense'),
        dataIndex: 'driverLicense',
        key: 'driverLicense',
        width: 80,
        align: 'center',
      },
    ]
    return listColumns
  }

  get listDataSource() {
    return this.dataResult.items.map((item) => {
      const result = {
        key: item.msg_id,
        id: item.msg_id,
        faceimg: item.faceimg,
        personname: item.person_name,
        personid: item.person_id,
        org: item.org_name,
        temperature: item.temperature,
        alcohol: item.alcohol,
        alcohol_alarm: item.alcohol_alarm,
        device: item.device_name,
        driverLicense: item.driverLicense,
      }
      if (item.log_type === 'login') {
        result['logtype'] = this.$i18n.tc('log.logtype.login')
      } else if (item.log_type === 'logout') {
        result['logtype'] = this.$i18n.tc('log.logtype.logout')
      } else if (item.log_type === 'alcohol') {
        result['logtype'] = this.$i18n.tc('log.logtype.alcohol')
      } else if (item.log_type === 'on_car') {
        result['logtype'] = this.$i18n.tc('log.logtype.on_car')
      } else if (item.log_type === 'off_car') {
        result['logtype'] = this.$i18n.tc('log.logtype.off_car')
      }

      if (item.temperature) {
        result['temperature'] =
          parseFloat(item.temperature).toFixed(1) +
          '℃ ' +
          '(' +
          (item.temperature_alarm === 'normal'
            ? this.$i18n.tc('normal')
            : this.$i18n.tc('abnormal')) +
          ')'
      }

      if (item.driver_license_info) {
        if (item.driver_license_info === '1') {
          result['driverLicense'] = this.$i18n.tc('confirmed')
        } else if (item.driver_license_info === '0') {
          result['driverLicense'] = this.$i18n.tc('confirmed_failed')
        }
      }
      return result
    })
  }

  // delete row selected
  // VUE 生命周期
  private async created() {
    const enterpriseData: any = await getRanterTreeData(
      this.$store.state.main.userProfile.user_type
    )
    this.defaultSearch.org_id = this.ranterIDCose
    this.orgList = []
    this.generateOrgList(enterpriseData, '')
    this.handleSubmit(null)
    // deviceList
    await getDeviceDataIntable(this.$store.state.main.userProfile.user_type, this.ranterIDCose)
    this.deviceList = this.$store.state.main.deviceHardWare.deviceData
    await getPersonBranchORG(this.$store.state.main.userProfile.user_type, this.ranterIDCose)
    this.personList = this.$store.state.main.userAuthorityShow.userData
    this.changeOrg(this.ranterIDCose)
  }

  @Watch('routePath') public theRoutePath() {
    console.log('routePath', this.routePath)
    this.form.resetFields()
    this.changeOrg(this.ranterIDCose)
    this.currentPage = 1
    // 重新请求数据
    this.handleSubmit(null)
  }

  public changeOrg(oId) {
    // 查询出oId的所有子组织
    const childOrgs = _.filter(this.orgList, function(org) {
      return _.indexOf(org.ancestors, oId.toString()) > -1 || org.id === oId;
    })
    const deviceListT: any = []
    const personListT: any = []
    this.deviceList.forEach((dev) => {
      const orgIds = dev.org_ids
      orgIds.forEach((org) => {
        //if (org.id === oId) {
        if (_.findIndex(childOrgs, function(childorg) { return org.id === childorg.id; }) > -1) {
          const idx = _.findIndex(deviceListT, (device: { id: any }) => { return device.id === dev.id })
          if (idx === -1) {
            deviceListT.push(dev)
          }
        }
      })
    })
    this.personList.forEach((per) => {
      const orgIds = per.org_ids
      orgIds.forEach((org) => {
        //if (org.id === oId) {
        if (_.findIndex(childOrgs, function(childorg) { return org.id === childorg.id; }) > -1) {
          const idx = _.findIndex(personListT, (person: { id: any }) => { return person.id === per.id })
          if (idx === -1) {
            personListT.push(per)
          }
        }
      })
    })
    this.form.setFieldsValue({ device_id: null, person_id: null})
    this.personListT = personListT
    this.deviceListT = deviceListT
  }

  @Watch('$store.state.main.screen.width') public windowHeight() {
    this.theWindowW = document.body.clientWidth
    if (!this.listMode) {
      this.changeLogDataWidth()
    }
  }

  public changeSubmitData(data) {
    data.start_time = data.start_time
      ? moment(data.start_time).format('YYYY-MM-DD') + ' 00:00:00'
      : null
    data.end_time = data.end_time ? moment(data.end_time).format('YYYY-MM-DD') + ' 23:59:59' : null
    data.time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone
    data.file_flag = false
    data.alcohol_flag = this.routePath === 'alcohol_log'
    return data
  }

  public csvFilesOut() {
    this.form.validateFields((err, values) => {
      if (!err) {
        const data = this.changeSubmitData(values)
        data.file_flag = true
        this.downloadFile(data)
      }
    })
  }
  public async downloadFile(data) {
    await downloadRecognizeLog(data)
    // 定义文件内容，类型必须为Blob 否则createObjectURL会报错
    const content = new Blob([await this.$store.state.main.messagePro])
    // 生成url对象
    const urlObject: any = window.URL || window.webkitURL || window
    const url = urlObject.createObjectURL(content)
    // 生成<a></a>DOM元素
    const el = document.createElement('a')
    // 链接赋值
    el.href = url
    el.download = 'device_log.xlsx'
    // 必须点击否则不会下载
    el.click()
    // 移除链接释放资源
    urlObject.revokeObjectURL(url)
  }

  public handleSubmit(e) {
    if (e) {
      e.preventDefault()
      this.currentPage = 1
    }
    this.form.validateFields((err, values) => {
      if (!err) {
        if (!values.start_time) {
          this.$message.error(this.$i18n.tc('log.seacrch.start_time_required'))
          return
        }
        if (!values.org_id) {
          values.org_id = this.ranterIDCose
        }
        values.limit = this.pageSize
        values.page = this.currentPage
        const data = this.changeSubmitData(values)
        data.file_flag = false
        this.searchLog(data)
      }
    })
  }

  public async searchLog(data) {
    const result = await searchRecognizeLog(data)
    this.dataResult = result
    const logImg = {}
    _.forEach(this.dataResult.items, (item) => {
      logImg[item.msg_id] = defaultImg
    })
    this.logImg = logImg
    this.downloadLogFile()
  }

  public downloadLogFile() {
    _.forEach(this.dataResult.items, (item) => {
      getLogFile(this.ranterIDCose, item.image)
        .then((data) => {
          getBase64(data).then((d) => {
            this.logImg[item.msg_id] = d
          })
        })
        .catch((err) => {
          console.log(err)
        })
    })
  }

  public resetForm() {
    this.form.resetFields()
    this.changeOrg(this.ranterIDCose)
    this.currentPage = 1
    this.handleSubmit(null)
  }

  public onChangePage(current) {
    this.currentPage = current
    this.handleSubmit(null)
  }

  public generateOrgList(data, space) {
    for (const i of data) {
      const node: any = i
      this.orgList.push({ id: node.id, name: space + ' ' + node.name, ancestors: node.ancestors.split(',') })
      if (node.children) {
        this.generateOrgList(node.children, space + ' ')
      }
    }
  }

  public mounted() {
    this.changeLogDataWidth()
  }
  public changeLogDataWidth() {
    if (this.theWindowW <= 1440) {
      this.pageSizeForCard = 8
      this.resultWidth = 1120
    } else if (this.theWindowW < 1920) {
      this.pageSizeForCard = 10
      this.resultWidth = 1400
    } else if (this.theWindowW >= 1920) {
      this.pageSizeForCard = 12
      this.resultWidth = 1680
    }
    this.pageSize = this.pageSizeForCard
  }

  // 切换显示模式
  public changeListMode() {
    setTimeout(() => {
      const inputElement = document.querySelector('.ant-pagination-options-quick-jumper input') as HTMLInputElement
      if (inputElement !== null) {
        inputElement.value = ''
      }
    }, 1000)
    this.listMode = !this.listMode
    // 设置分页和分页大小
    this.currentPage = 1
    if (this.listMode) {
      this.pageSize = 20
    } else {
      this.pageSize = this.pageSizeForCard
    }
    // 重新请求数据
    this.handleSubmit(null)
  }
}
