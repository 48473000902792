import { requestToken } from '../../../api/manage'
import { store } from '@/store'

export function searchRecognizeLog(config) {
  const url = `/api/v1/recognizes/searchRecognizeLog`
  return requestToken({
    url,
    method: 'get',
    params: config
  })
}

export function downloadRecognizeLog(config) {
  const url = `/api/v1/recognizes/searchRecognizeLog`
  const response : any =  requestToken({
    url,
    method: 'get',
    params: config,
    responseType: 'blob',
    timeout: 0
  })
  store.state.main.messagePro = response
}

export  function getLogFile(orgId: any, filePath: any) {
  return requestToken({
    headers: {
      Accept: 'application/json',
    },
    url: '/api/v1/persons/getFile/' + orgId + '/' + filePath,
    method: 'get',
    responseType: 'blob'
  })
}
