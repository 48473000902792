<template>
  <div class="driver-license-reader-container">
    <div class="driver-license-reader-first" v-if="readStep === 0">
      <img
        class="driver-license-read-sample"
        :src="require('@/assets/driver-license-read-sample.png')"
        alt=""
      />
      <div style="margin-top: 20px">{{ $t('user.authority.driver_license_sample_tips') }}</div>
      <a-checkbox style="margin-top: 40px" v-model:checked="remember">{{
        $t('user.authority.driver_license_reader_remember')
      }}</a-checkbox>
      <a-button
        type="primary"
        style="margin-top: 20px; padding-left: 50px; padding-right: 50px"
        @click="read"
        >{{ $t('user.authority.driver_license_reader_start') }}</a-button
      >
    </div>
    <div class="driver-license-reader-step-container" v-if="readStep === 1">
      <a-icon type="loading" style="font-size: 46px" />
      <a-button
        type="primary"
        style="position: absolute; bottom: 50px; padding-left: 50px; padding-right: 50px"
        @click="readStep = 4"
        >{{ $t('cancel') }}</a-button
      >
    </div>
    <!-- 读取成功 -->
    <div class="driver-license-reader-step-container" v-if="readStep === 2">
      <div class="driver-license-reader-info-text">
        {{ $t('user.authority.driver_license_reader_success') }}
      </div>
      <a-button
        type="primary"
        style="position: absolute; bottom: 50px; padding-left: 50px; padding-right: 50px"
        @click="close"
        >{{ $t('ok') }}</a-button
      >
    </div>
    <!-- 读取超时 -->
    <div class="driver-license-reader-step-container" v-if="readStep === 3">
      <img class="driver-license-read-warning" :src="require('@/assets/warning.png')" alt="" />
      <div class="driver-license-reader-info-text">
        {{ $t('user.authority.driver_license_reader_timeout') }}
      </div>
      <div class="driver-license-reader-info-text">
        {{ $t('user.authority.driver_license_reader_model_name') + ': ' + readerName }}
      </div>
      <div class="driver-license-reader-info-text">
        {{ $t('user.authority.driver_license_reader_serial_number') + ': ' + readerSerialNumber }}
      </div>
      <a-button
        type="primary"
        style="position: absolute; bottom: 50px; padding-left: 50px; padding-right: 50px"
        @click="readStep = 0"
        >{{ $t('ok') }}</a-button
      >
    </div>
    <!-- 读取取消 -->
    <div class="driver-license-reader-step-container" v-if="readStep === 4">
      <img class="driver-license-read-warning" :src="require('@/assets/warning.png')" alt="" />
      <div class="driver-license-reader-info-text">
        {{ $t('user.authority.driver_license_reader_cancel') }}
      </div>
      <a-button
        type="primary"
        style="position: absolute; bottom: 50px; padding-left: 50px; padding-right: 50px"
        @click="readStep = 0"
        >{{ $t('ok') }}</a-button
      >
    </div>
  </div>
</template>

<script>
import CryptoJs from 'crypto-js'
import moment from 'moment'

export default {
  name: 'DriverLicenseReader',
  props: ['visible'],
  data() {
    return {
      // 是否记住上次选择的设备
      remember: false,
      // 当前进度(0: 开始画面, 1: 读取中, 2: 读取完成 3: 读取超时 4: 读取取消)
      readStep: 0,
      // 超时时间
      timeout: 60000,
      // 读取ic卡开始时间
      readStartTime: null,
      // 读卡器名称
      readerName: '',
      // 读卡器序列号
      readerSerialNumber: '',
    }
  },
  mounted() {
    const remember = localStorage.getItem('driver_license_reader_remember')
    if (remember && remember === 'true') {
      this.remember = true
      this.readStep = 1
      navigator.usb.getDevices().then((devices) => {
        if (devices.length > 0) {
          // 使用上次用户选择的设备
          let device = null
          for (let i = 0; i < devices.length; i++) {
            if (
              devices[i].manufacturerName === 'SONY' &&
              devices[i].productName.includes('FeliCa')
            ) {
              device = devices[i]
              break
            }
          }
          if (device) {
            console.log(device.productName)
            console.log(device.manufacturerName)
            this.readStartTime = new Date()
            this.show(device)
          }
        } else {
          // 如果没有以前选择过的设备，请求用户选择一个设备
          this.requestDevice()
        }
      })
    }
  },
  methods: {
    // 关闭画面
    close() {
      this.$emit('close')
    },

    read() {
      localStorage.setItem('driver_license_reader_remember', this.remember)
      this.requestDevice()
    },

    requestDevice() {
      this.readStep = 1
      const filters = [{}]
      navigator.usb
        .requestDevice({
          filters: filters,
        })
        .then((usbDevice) => {
          console.log('Product name: ' + usbDevice.productName)
          console.log(usbDevice.manufacturerName)
          this.readStartTime = new Date()
          this.show(usbDevice)
        })
        .catch((e) => {
          console.log('There is no device. ' + e)
        })
    },

    async show(device) {
      if (device) {
        this.readerName = device.productName
        this.readerSerialNumber = device.serialNumber
      }
      try {
        // 检查设备是否已经打开
        if (!device.opened) {
          await device.open()
        }
        // 检查接口是否已经被 claim
        if (!device.configuration.interfaces[1].claimed) {
          await device.claimInterface(1)
        }

        // 驾照读取
        await this.send(device, [0x00, 0xa4, 0x02, 0x0c, 0x02, 0x2f, 0x01])
        await this.receive(device, 12)
        await this.sleep(1000)
        await this.send(device, [0x00, 0xb0, 0x00, 0x00, 0x11])
        let x = await this.receive(device, 29)
        console.log(x)
        let jiaofu =
          x[15].toString(16) + x[16].toString(16) + '/' + x[17].toString(16) + '/' + x[18].toString(16)
        let youxiao =
          x[19].toString(16) + x[20].toString(16) + '/' + x[21].toString(16) + '/' + x[22].toString(16)
        // alert('申请日:' + jiaofu + '  有效期:' + youxiao)
        console.log('申请日:' + jiaofu + '  有效期:' + youxiao)
        const date1 = moment(jiaofu, "YYYY/MM/DD")
        const date2 = moment(youxiao, "YYYY/MM/DD")
        if (date1.isValid() && date2.isValid()) {
          this.readStep = 2
          x = x.map((item) => {
            return item.toString(16).padStart(2, '0')
          }).join('')
          const hash = CryptoJs.MD5(x).toString()
          this.$emit('success', hash)
        } else {
          await this.sleep(2000)
          this.show(device)
        }

        // Suica读取
        // await this.send(device, [0xff, 0xa4, 0x00, 0x01, 0x02, 0x0f, 0x09])
        // await this.receive(device, 12)
        // await this.sleep(1000)
        // await this.send(device, [0xff, 0xb0, 0x00, 0x00, 0x00])
        // let x = await this.receive(device, 28)
        // x = x.map((item) => {
        //   return item.toString(16).padStart(2, '0')
        // }).join('')
        // alert('Suica Read :' + x)
        // this.readStep = 2
        // const hash = CryptoJs.MD5(x).toString()
        // this.$emit('success', hash)

      } catch (error) {
        console.log(error)
        if (this.readStep === 1 && this.visible) {
          const now = new Date()
          if (now - this.readStartTime > this.timeout) {
            this.readStep = 3
          } else {
            await this.sleep(2000)
            this.show(device)
          }
        }
      } finally {
        // await device.close()
      }
    },

    async sleep(time) {
      return new Promise((resolve) => setTimeout(resolve, time))
    },

    async send(device, data) {
      let uint8a = new Uint8Array(this.add_PC_to_RDR_Escape(data))
      await device.transferOut(2, uint8a)
      await this.sleep(10)
    },

    add_PC_to_RDR_Escape(e) {
      const t = e.length
      let i = new Uint8Array(10 + t)
      return (
        (i[0] = 107),
        (i[1] = 255 & t),
        (i[2] = (t >> 8) & 255),
        (i[3] = (t >> 16) & 255),
        (i[4] = (t >> 24) & 255),
        (i[5] = 0),
        (i[6] = 0),
        0 != t && i.set(e, 10),
        i
      )
    },

    async receive(device, len) {
      console.log('<<<<<<<<<<' + len)
      let data = await device.transferIn(2, len)
      console.log(data)
      await this.sleep(10)
      let arr = []
      for (let i = data.data.byteOffset; i < data.data.byteLength; i++) {
        arr.push(data.data.getUint8(i))
      }
      console.log(arr)
      return arr
    },
  },
}
</script>

<style lang="less" scoped>
.driver-license-reader-container {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.driver-license-reader-first {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.driver-license-read-sample {
  width: 490px;
  height: 285px;
}
.driver-license-reader-step-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.driver-license-reader-info-text {
  font-size: 20px;
  font-weight: bold;
}
.driver-license-read-warning {
  width: 148px;
  height: 128px;
  margin-bottom: 50px;
}
</style>
